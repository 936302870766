<template>
    <font-awesome-icon
        icon="info-circle"
        @click="detalhar()"
        size="lg"
        class="icon-button p-text-primary"
        title="Detalhar"
        v-if="$temAcessoView('PROJETOS-02')"
    />
</template>

<script>
export default {
    props: {
        projeto: {
            type: Object,
        },
    },

    methods: {
        detalhar() {
            this.$router.push({
                name: 'Projetos_Detalhar',
                params: {
                    id: this.projeto.projetoId,
                },
            });
        },
    },
};
</script>
