<template>
    <div class="p-grid" v-if="mostrarListagem">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-list"></i> Projetos</h5>
                    <btn-refresh @click="atualizar()"></btn-refresh>
                </div>
                <tabela :data="projetos" dataKey="projetoId" :globalFilterFields="['nome', 'cnpj', 'descricao', 'statusDescr']" stateKey="dt-projetos">
                    <template #botoes>
                        <btn-inserir></btn-inserir>
                    </template>
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <btn-detalhar :projeto="slotProps.data"></btn-detalhar>
                            </template>
                        </Column>
                        <Column field="nome" header="Nome" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.nome }}
                            </template>
                        </Column>
                        <Column field="cnpj" header="CNPJ" :sortable="true">
                            <template #body="slotProps">
                                {{ $toCnpj(slotProps.data.cnpj) }}
                            </template>
                        </Column>
                        <Column field="descricao" header="Descrição" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.descricao }}
                            </template>
                        </Column>
                        <Column field="tipo" header="Tipo" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.tipoDescr }}
                            </template>
                        </Column>
                        <Column field="statusDescr" header="Status" :sortable="true">
                            <template #body="slotProps">
                                <status :status="slotProps.data.statusDescr"></status>
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
    <router-view></router-view>
</template>

<script>
import ProjetosServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            projetos: null,
        };
    },

    methods: {
        obterProjetos() {
            this.$store.dispatch('addRequest');
            ProjetosServices.obterTodos().then((response) => {
                if (response && response.success) {
                    this.projetos = response.data;
                } else {
                    this.projetos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterProjetos();
        },
    },

    mounted() {
        this.obterProjetos();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Projetos';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Projetos') {
                if (this.$store.getters.atualizar) {
                    this.obterProjetos();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
